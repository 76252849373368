import React from "react"
import { MDXProvider } from "@mdx-js/react" 
import SmartCaption from "../../components/SmartCaption"

const shortcodes ={ SmartCaption }

export default ({ children }) => (
  <MDXProvider components={shortcodes}>
    {children}
  </MDXProvider>
)