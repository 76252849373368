import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/mdx/default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const SmartCaption = makeShortcode("SmartCaption");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Merkle_tree",
        "target": "_blank",
        "rel": "nofollow noreferrer"
      }}>{`Merkle Tree’s`}</a>{` are the same structures used in `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Git",
        "target": "_blank",
        "rel": "nofollow noreferrer"
      }}>{`Git`}</a>{`, `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/ZFS",
        "target": "_blank",
        "rel": "nofollow noreferrer"
      }}>{`ZFS file systems`}</a>{`, and `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Blockchain",
        "target": "_blank",
        "rel": "nofollow noreferrer"
      }}>{`blockchain technology`}</a>{`. A Merkle tree is a structure where each node is labeled with the cryptographic hash of the labels of all the nodes under it.`}</p>
    <p>{`yeah`}</p>
    <p><img parentName="p" {...{
        "src": "https://upload.wikimedia.org/wikipedia/commons/thumb/9/95/Hash_Tree.svg/620px-Hash_Tree.svg.png",
        "alt": "Binary Hash Tree, credit Wikipedia"
      }}></img></p>
    <blockquote>
      <p><small>A binary hash tree <cite>Illustration by <a href="https://en.wikipedia.org/wiki/User:Davidgothberg" className="no-underline">David Göthberg</a></cite></small></p>
    </blockquote>
    <SmartCaption credit="Illustration by David Göthberg, no copyright" crediturltext="David Göthberg" crediturl="https://en.wikipedia.org/wiki/User:Davidgothberg" mdxType="SmartCaption">
  A binary hash tree
    </SmartCaption>
    <SmartCaption noblockquote mdxType="SmartCaption"> This is a test </SmartCaption>

    <style {...{
      "className": "grvsc-styles"
    }}>{`
  .grvsc-container {
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    padding-top: 1rem;
    padding-top: var(--grvsc-padding-top, var(--grvsc-padding-v, 1rem));
    padding-bottom: 1rem;
    padding-bottom: var(--grvsc-padding-bottom, var(--grvsc-padding-v, 1rem));
    border-radius: 8px;
    border-radius: var(--grvsc-border-radius, 8px);
    font-feature-settings: normal;
  }
  
  .grvsc-code {
    display: inline-block;
    min-width: 100%;
  }
  
  .grvsc-line {
    display: inline-block;
    box-sizing: border-box;
    width: 100%;
    padding-left: 1.5rem;
    padding-left: var(--grvsc-padding-left, var(--grvsc-padding-h, 1.5rem));
    padding-right: 1.5rem;
    padding-right: var(--grvsc-padding-right, var(--grvsc-padding-h, 1.5rem));
  }
  
  .grvsc-line-highlighted {
    background-color: var(--grvsc-line-highlighted-background-color, transparent);
    box-shadow: inset var(--grvsc-line-highlighted-border-width, 4px) 0 0 0 var(--grvsc-line-highlighted-border-color, transparent);
  }
  
`}</style>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      